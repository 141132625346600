<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车道id" prop="laneId">
                <a-input v-model="queryParam.laneId" placeholder="请输入车道id" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="路线ID" prop="routeId">
                <a-input v-model="queryParam.routeId" placeholder="请输入路线ID" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="作业类型" prop="taskType">
                  <a-select placeholder="请选择作业类型" v-model="queryParam.taskType" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in taskTypeOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="行驶位置" prop="drivingPosition">
                  <a-select
                    placeholder="请选择行驶位置"
                    v-model="queryParam.drivingPosition"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="(d, index) in drivingPositionOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="速度(kph)" prop="speedLimit">
                  <a-input v-model="queryParam.speedLimit" placeholder="请输入速度(kph)" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:lanePlanning:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:lanePlanning:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:iot:lanePlanning:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:lanePlanning:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{float: 'right'}"
          icon="reload"
          @click="getList" /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :taskTypeOptions="taskTypeOptions"
        :drivingPositionOptions="drivingPositionOptions"
        :statusOptions="statusOptions"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="taskType" slot-scope="text, record">
          {{ taskTypeFormat(record) }}
        </span>
        <span slot="drivingPosition" slot-scope="text, record">
          {{ drivingPositionFormat(record) }}
        </span>
        <span slot="status" slot-scope="text, record">
          {{ statusFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:lanePlanning:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:lanePlanning:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['iot:lanePlanning:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:lanePlanning:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listLanePlanning, delLanePlanning, exportLanePlanning } from '@/api/iot/lanePlanning'
import CreateForm from './modules/CreateForm'

export default {
  name: 'LanePlanning',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 车道状态:0未驶过，1已驶过字典
      statusOptions: [],
      // 作业类型字典
      taskTypeOptions: [],
      // 行驶位置字典
      drivingPositionOptions: [],
      // 查询参数
      queryParam: {
        laneId: null,
        routeId: null,
        taskType: null,
        drivingPosition: null,
        speedLimit: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车道id',
          dataIndex: 'laneId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '路线ID',
          dataIndex: 'routeId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '作业类型',
          dataIndex: 'taskType',
          scopedSlots: { customRender: 'taskType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '行驶位置',
          dataIndex: 'drivingPosition',
          scopedSlots: { customRender: 'drivingPosition' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '速度(kph)',
          dataIndex: 'speedLimit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车道顺序',
          dataIndex: 'orderNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车道状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('iot_lane_status').then((response) => {
      this.statusOptions = response.data
    })
    this.getDicts('iot_lane_taskType').then((response) => {
      this.taskTypeOptions = response.data
    })
    this.getDicts('iot_lane_drivingPosition').then((response) => {
      this.drivingPositionOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询车道规划列表 */
    getList() {
      this.loading = true
      listLanePlanning(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 车道状态:0未驶过，1已驶过字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    // 作业类型(字典表设定)字典翻译
    taskTypeFormat(row, column) {
      return this.selectDictLabel(this.taskTypeOptions, row.taskType)
    },
    // 行驶位置(字典表设定)字典翻译
    drivingPositionFormat(row, column) {
      return this.selectDictLabel(this.drivingPositionOptions, row.drivingPosition)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        laneId: undefined,
        routeId: undefined,
        taskType: undefined,
        drivingPosition: undefined,
        speedLimit: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delLanePlanning(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportLanePlanning(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
